import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-entity-county',
  templateUrl: './edit-entity-county.component.html',
  styleUrls: ['./edit-entity-county.component.scss']
})
export class EditEntityCountyComponent implements OnInit {

  public title: any = '';
  public valueFromComponent: any = '';
  public labelFromComponent: any = '';
  public buttonName: any = '';
  @Input() label;
  @Input() value;
  @Input() heading;
  @Input() button;
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.title = this.heading;
    this.valueFromComponent = this.value;
    this.labelFromComponent = this.label;
    this.buttonName = this.button;
  }
}
