import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-vendor',
  templateUrl: './edit-vendor.component.html',
  styleUrls: ['./edit-vendor.component.scss']
})
export class EditVendorComponent implements OnInit {

  public title: any = '';
  public valueFromComponent: any = '';
  public labelFromComponent: any = '';
  public valueFromComponentPayeeBank: any = '';
  public labelFromComponentPayeeBank: any = '';
  public valueFromComponentCardDetails: any = '';
  public labelFromComponentCardDetails: any = '';
  public buttonName: any = '';
  public data: any = '';
  @Input() label;
  @Input() value;
  @Input() labelPayeeBank;
  @Input() valuePayeeBank;
  @Input() labelCardDetails;
  @Input() valueCardDetails;
  @Input() heading;
  @Input() button;
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.title = this.heading;
    this.valueFromComponent = this.value;
    this.labelFromComponent = this.label;
    this.valueFromComponentPayeeBank = this.valuePayeeBank;
    this.labelFromComponentPayeeBank = this.labelPayeeBank;
    this.valueFromComponentCardDetails = this.valueCardDetails;
    this.labelFromComponentCardDetails = this.labelCardDetails;
    this.buttonName = this.button;
  }

  onSave(){
    this.data = {
      name :this.valueFromComponent,
      payeebank:this.valueFromComponentPayeeBank,
      carddetails:this.valueFromComponentCardDetails
    }
    this.activeModal.close(this.data);
  }
}
