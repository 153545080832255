import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {

  constructor() { }
  getUserTimeZoneOffSet(): number {
    return new Date().getTimezoneOffset();
  }
}
