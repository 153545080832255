import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ToastrModule } from 'ngx-toastr';
import { ScrollbarModule } from 'ngx-scrollbar';
import { LoaderComponent } from '../../layout/bs-component/components/loader/loader.component';
import { CommonService } from '../services/common.service';
import { PropertyService } from '../services/property.service';
import { ApiService } from '../services/api.service';
import { ConfirmDialogComponent } from '../../layout/bs-component/components/confirm-dialog/confirm-dialog.component';
import { AutoLogoutComponent } from '../../layout/bs-component/components/auto-logout/auto-logout.component';
import { AutoLogoutService } from '../services/auto-logout.service';
import { EditEntityCountyComponent } from '../../layout/bs-component/components/edit-entity-county/edit-entity-county.component';
import { EditVendorComponent } from '../../layout/bs-component/components/edit-vendor/edit-vendor.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
    ScrollbarModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    })
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
    ScrollbarModule,
    ToastrModule,
    LoaderComponent,
    AutoLogoutComponent
  ],
  providers: [CommonService, PropertyService, ApiService, AutoLogoutService],
  declarations: [LoaderComponent, ConfirmDialogComponent, AutoLogoutComponent, EditEntityCountyComponent,EditVendorComponent],
  entryComponents: [ConfirmDialogComponent, EditEntityCountyComponent,EditVendorComponent]
})
export class SharedModule { }
