import { Component, OnInit } from '@angular/core';
import { AutoLogoutService } from '../../../../shared/services/auto-logout.service';

@Component({
  selector: 'app-auto-logout',
  templateUrl: './auto-logout.component.html',
  styleUrls: ['./auto-logout.component.scss'],
  providers: [AutoLogoutService]
})
export class AutoLogoutComponent implements OnInit {

  constructor(public service: AutoLogoutService) { }

  ngOnInit() {
    this.service.initListener();
    localStorage.setItem('lastAction', Date.now().toString());
  }
}
