import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private _api: ApiService) { }

  addProperty(data: any): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/AddProperty', data);
  }
  getAllProperties(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetAllProperties');
  }
  getPropertyByID(PropertyID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetPropertyByID/' + PropertyID);
  }
  blockProperty(PropertyID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/BlockProperty/' + PropertyID);
  }
  unBlockProperty(PropertyID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/UnBlockProperty/' + PropertyID);
  }
  updateProperty(data: any): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UpdateProperty', data);
  }
  getAllEntity(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/getAllEntity');
  }
  getAllCounty(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/getAllCounty');
  }
  updateCounty(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UpdateCounty', data);
  }
  updateEntity(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UpdateEntity', data);
  }
  addCounty(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/AddCounty', data);
  }
  addEntity(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/AddEntity', data);
  }
  deleteEntity(id: any) {
    return this._api.apiCaller('get', '/PIMS/DeleteEntity/' + id);
  }
  deleteCounty(id: any) {
    return this._api.apiCaller('get', '/PIMS/DeleteCounty/' + id);
  }
  getAllVendor(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetAllVendors');
  }
  updateVendor(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UpdateVendor', data);
  }
  addVendor(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/AddVendor', data);
  }
  deleteVendor(id: any) {
    return this._api.apiCaller('get', '/PIMS/DeleteVendor/' + id);
  }
  getVendorByID(VendorID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetVendorByID/' + VendorID);
  }
  addRentExpense(data: any): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/AddRentExpense', data);
  }
  getAllRentExpense(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetAllRentExpense');
  }
  getRentExpenseByID(RentID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetRentExpenseByID/' + RentID);
  }
  deleteRentExpense(RentID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/DeleteRentExpense/' + RentID);
  }
  updateRentExpense(data: any): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UpdateRentExpense', data);
  }
  getAllMaid(): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetAllMaid');
  }
  deleteMaid(MaidID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/DeleteMaid/' + MaidID);
  }
  updateMaid(data: any): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/UpdateMaid', data);
  }
  addMaid(data): Observable<any> {
    return this._api.apiCaller('post', '/PIMS/AddMaid', data);
  }
  getMaidByID(MaidID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetMaidByID/' + MaidID);
  }
  addMaidReservation(data: any): Observable<any> {
    console.log('api call---->', data);
    return this._api.apiCaller('post', '/PIMS/AddMaidReservations', data);
  }
  GetMaidReservationsByReservationID(ReservationID): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/GetMaidReservationsByReservationID/' + ReservationID);
  }
  deleteMaidReservation(maidReservationId): Observable<any> {
    return this._api.apiCaller('get', '/PIMS/DeleteMaidReservations/' + maidReservationId);
  }
}
