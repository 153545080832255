// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //  api: 'http://199.195.118.212/PIMSAPI/api',
   //api:'https://tajvapi.cwassets.com/api',
   //api:'http://hwsrv-491028.hostwindsdns.com/TAJVAPI/api',
   api:'https://tajvapi.auctionscitywide.com/api',
// api: 'http://localhost:5006/api'
  // imagePath: 'http://199.195.118.212/PMISAPI',
};
